export const proyectos = {
    "titulo": "PROYECTOS",
    "proyectos": [
        {
            "titulo": "HOTELES THe",
            "descripcion": "Durante mis prácticas empresa empecé un gran proyecto que se basaba en replicar las páginas web de la cadena hotelera Hoteles THe. Estas estaban hechas con Wordpress y yo me encargué de pasarlas a HTML, CSS y Javascript.",
            "imagen": {
                "URL": "./imagenes/proyectos/PROYECTO_HOTELES_THE.png",
                "alt": "Proyecto de Hoteles THe"
            },
            "herramientas": [
                "HTML5",
                "CSS",
                "Javascript"
            ]
        },
        {
            "titulo": "PORTAFOLIO WEB",
            "descripcion": "Mi portafolio web es una idea que me surgió cuando finalicé mis estudios. Crear un sitio donde la gente me conozca y donde exponer lo que hago, lo que he hecho y lo que haré.",
            "imagen": {
                "URL": "./imagenes/proyectos/PROYECTO_PORTAFOLIO.png",
                "alt": "Proyecto de mi portafolio"
            },
            "herramientas": [
                "HTML5",
                "CSS",
                "React JS"
            ] 
        },
        {
            "titulo": "Sixty Nine Apartments",
            "descripcion": "Este proyecto trató de una aplicación sobre la venta de tickets para futuros sorteos. Se desarrolló la página principal donde una persona podía ver los sorteos activos, comprar los tickets y ver los resultados de los sorteos finalizados; y un panel de administración para la gestión de los sorteos.",
            "imagen": {
                "URL": "./imagenes/proyectos/PROYECTO_NO_ACTIVO.png",
                "alt": "Proyecto de Hoteles THe"
            },
            "herramientas": [
                "HTML5",
                "CSS",
                "Javascript"
            ]
        },
        {
            "titulo": "FACTURAMEL",
            "descripcion": "Facturamel es una aplicación pendiente de desarrollo para el control de facturación de un autónomo, agenda de tareas, control de clientes, etc. La idea de esta aplicación es hacerla con Lavarel 9.",
            "imagen": {
                "URL": "./imagenes/proyectos/PROYECTO_EN_DESARROLLO.png",
                "alt": "Este proyecto está en desarrollo"
            },
            "herramientas": [
                "PHP",
                "Laravel",
                "HTML5",
                "CSS"
            ]
        },
        {
            "titulo": "WIKINTERN",
            "descripcion": "La idea de WIKINTERN es, como su propio nombre indica, desarrollar una wiki de uso interno para la gestión de procedimientos, anotaciones, etc. No está pensada para ser una gran aplicación pero tiene muchas vías de desarrollo futuras.",
            "imagen": {
                "URL": "./imagenes/proyectos/PROYECTO_EN_DESARROLLO.png",
                "alt": "Este proyecto está en desarrollo"
            },
            "herramientas": []
        },
    ]
}
   
