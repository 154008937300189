import React from "react";
import { proyectos } from "../../data/proyectos";
import Card from "../Commons/Card";
import Title from "../Commons/Title";
import './Projects.scss'

export default function Projects() {
  return (
    <section id="projects">
      <Title>{proyectos.titulo}</Title>
      <div className="list_of_cards">
        {proyectos.proyectos.map((proyecto) => (
          <Card
            key={proyecto.titulo}
            titulo={proyecto.titulo}
            descripcion={proyecto.descripcion}
            imagen={proyecto.imagen}
            herramientas={proyecto.herramientas}
          />
        ))}
      </div>
    </section>
  );
}
