import React from "react";

export default function Title({ children }) {
  return (
    <div id="section_title">
      <h2>{children}</h2>
      <hr/>
    </div>
  );
}
