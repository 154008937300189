export const contacto = {
  titulo: "¿Quieres hablar?",
  contactos: [
    {
      URL: "https://www.linkedin.com/in/gonzalo-alexander-martin-ramirez",
      icono: "./imagenes/iconos/40x40_LINKEDIN.svg",
      alt: "Icono: Ir a Linkeding",
      target: "_blank"
    },
    {
      URL: "mailto:desarrollo@gmrtin.es",
      icono: "./imagenes/iconos/40x40_EMAIL.svg",
      alt: "Icono: Enviar correo",
    },
    // {
    //   URL: "https://github.com/alexmrtin1",
    //   icono: "./imagenes/iconos/40x40_GIT.svg",
    //   alt: "Icono: Ir a Github",
    // },
  ],
};
