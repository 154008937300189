import React from 'react'
import './Footer.scss'

export default function Footer() {
  return (
    <footer>
      <p>Copyright &copy; 2022</p>
      <p>Alexander Martín &mdash; Administrador de sistemas y desarrollador web</p>
    </footer>
  )
}
