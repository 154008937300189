import React from "react";
import "./Skill.scss";
import { habilidades } from "../../data/habilidades";
import Title from "../Commons/Title";
import SkillCard from "./SkillCard/SkillCard";

export default function Skills() {
  return (
    <section id="skills">
      <Title> {habilidades.titulo}</Title>
      <div id="skills-cards">
        {habilidades.habilidades.map((skill) => (
          <SkillCard
            titulo={skill.titulo}
            descripcion={skill.descripcion}
            icono={skill.icono}
          />
        ))}
      </div>
    </section>
  );
}
