import React from 'react'
import Title from '../Commons/Title'
import { contacto } from '../../data/contacto'
import './Contact.scss'

export default function Contact() {
  return (
    <section id='contact'>
      <Title>{ contacto.titulo }</Title>
      <div className='contact_content'>
        {contacto.contactos.map(contacto => <a href={contacto.URL} target={contacto.target ? contacto.target : ''}><img src={contacto.icono} alt={contacto.alt}  className='small_icon'/></a>)}
      </div>
    </section>
  )
}