import React, { useState } from 'react'
import './Navigation.scss'

export default function Navigation() {
  const [isActive, setIsActive] = useState(false)

  const toggleMenu = () => {
    setIsActive(!isActive)
    console.log('hola')
  }

  return (
    <nav id='navigation'>
      <span id="navigation_menu_icon" onClick={event => toggleMenu() }></span>
      <ul id='navigation_menu' className={isActive ? 'visible' : ''}>
        <li><a href="#aboutme">SOBRE MÍ</a></li>
        <li><a href="#skills">HABILIDADES</a></li>
        <li><a href="#projects">PROYECTOS</a></li>
        <li><a href="#contact">CONTACTO</a></li>
      </ul>
    </nav>
  )
}