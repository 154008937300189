import React from "react";
import './SkillCard.scss'

export default function SkillCard({ titulo, descripcion, icono }) {
  return (
    <div className="skill-card">
      <div className="skill-icon">
        <img src={icono.URL} alt={icono.alt} />
      </div>
      <div className="skill-content">
        <h3>{titulo}</h3>
        <p>{descripcion}</p>
      </div>
    </div>
  );
}
