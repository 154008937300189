import React from "react";
import Title from "../Commons/Title";
import { AboutMeData } from "../../data/sobre-mi";
import "./AboutMe.scss";

export default function AboutMe() {
  return (
    <section id="aboutme">
      <Title>{AboutMeData.titulo}</Title>
      <div className="section_content">
        {AboutMeData.textos.map((texto) => (
          <p dangerouslySetInnerHTML={ { __html: texto }}></p>
        ))}
        <a href="./CV_Gonzalo_Martin.pdf" download>Descarga mi CV</a>
      </div>
    </section>
  );
}
