import React from 'react'
import Navigation from '../Navigation/Navigation'
import profile_image from "../../images/400x400_FOTO_PERFIL.png";
import './Header.scss'

export default function Header() {
  return (
    <header>
      <Navigation />
      <div id="header_data_profile">
            <img src={profile_image} alt="Foto de perfil" id="profile_image" />
            <h1><span>Alexander</span> Martín</h1>
            <h3>ADMINISTRADOR DE SISTEMAS Y DESARROLLADOR WEB</h3>
      </div>
    </header>
  )
}
