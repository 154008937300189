import React from "react";

export default function Card({ titulo, descripcion, imagen, herramientas }) {
  return (
    <div className="card">
      <img src={imagen.URL} alt={imagen.alt} />
      <div className="card_content">
        <h3>{titulo}</h3>
        <p>{descripcion}</p>
      </div>
    </div>
  );
}
