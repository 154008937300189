export const habilidades = {
    titulo: "HABILIDADES",
    habilidades: [
        {
            titulo: "MAQUETACIÓN WEB RESPONSIVE",
            descripcion: "Tengo experiencia maquetando sitios web responsive adaptando cada uno a cualquier dispositivo móvil, tablet u ordenador.",
            icono: {
                URL: "./imagenes/iconos/WEBDESIGN.png",
                alt: "Icono de maquetación"
            }
        },
        {
            titulo: "DESARROLLO FULLSTACK",
            descripcion: "Soy capaz de adaptarme a cualquier parte del desarrollo, sea este el backend o el frontend. He desarrollado proyectos con PHP, Node JS, Express JS, React, Angular e Ionic.",
            icono: {
                URL: "./imagenes/iconos/DESARROLLO_WEB.png",
                alt: "Icono de desarrollo web"
            }
        },
        {
            titulo: "ADMINISTRACIÓN DE SISTEMAS",
            descripcion: "Tengo experiencia administrando sistemas con Ubuntu o Debian. Me enfoco más hacia la parte de los servicios web como Apache o Nginx pero también manejo servicios como MySQL, Docker, etc.",
            icono: {
                URL: "./imagenes/iconos/SISTEMAS.png",
                alt: "Icono de administración de sistemas"
            }
        },
        {
            titulo: "BASES DE DATOS",
            descripcion: "Tengo experiencia trabajando con bases de datos relacionales con MySQL y MariaDB; y no relacionales con MongoDB.",
            icono: {
                URL: "./imagenes/iconos/BBDD.png",
                alt: "Icono de bases de datos"
            }
        },
    ]
}